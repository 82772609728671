
.skillSection {
    width: 85%;
    margin: 0 auto; /* Center the section */
    padding: 20px;
    background-color: transparent; /* Remove background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center text within the section */
}

.skillHeading {
    font-size: 2.5em; /* Larger font size for the heading */
    font-weight: bold; /* Bold heading */
    color: white; /* White color for the heading */
    margin: 10px 0; /* Space above and below the heading */
    text-align: center; /* Center the heading */
    display: block; /* Ensures it behaves like a block element */
}

.skillContent {
    font-size: 1.1em; /* Slightly larger font for content */
    color: white; /* White color for the paragraph */
    font-weight: bold; /* Bold paragraph text */
    margin: 5px 0; /* Reduced space above and below the paragraph */
    text-align: center; /* Center the text */
    max-width: 600px; /* Optional: limit the width for better readability */
    margin-left: auto; /* Center alignment */
    margin-right: auto; /* Center alignment */
}

.skillBars {
    display: flex; /* Flexbox for skill bars */
    flex-direction: column; /* Stack skill bars vertically */
    align-items: center; /* Center skill bars */
    margin-top: 20px;
    
}

.skillBar {
    display: flex; /* Flexbox for each skill bar */
    align-items: center; /* Align items vertically */
    background-color: #333; /* Darker background for skill bars */
    border: 1px solid #555; /* Light border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Padding inside skill bars */
    margin: 10px 0; /* Margin above and below skill bars */
    width: 100%; /* Full width for skill bars */
    max-width: 900px; /* Optional max-width for better layout */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Shadow for skill bars */
    
}

.skillBarText {
    flex: 1; /* Allow text to take remaining space */
    text-align: left; /* Align text to the left */
}

.skillBarImg {
    width: 60px; /* Fixed width for images */
    height: 60px; /* Fixed height for images */
    margin-right: 15px; /* Space between image and text */
}

.skillBarText h2 {
    font-size: 1.5em; /* Font size for skill titles */
    color: white; /* White color for titles */
    font-weight: bold; /* Bold titles */
    margin: 0 0 5px; /* Space below the title */
}

.skillBarText p {
    font-size: 1em; /* Font size for skill descriptions */
    color: white; /* White color for descriptions */
    margin: 0; /* No margin */
}
.brLine{
    color: red;
    font-size: 20px;
    margin-bottom: 20px;
  }